import { defineRule, configure } from 'vee-validate';

import { required, email, numeric, min_value, max_value, regex, confirmed, one_of } from '@vee-validate/rules';

import { localize } from '@vee-validate/i18n';

import i18nEN from '../locale/en';
import { convertDurationWithMinutesAndSecondsToMinutes, convertDurationWithMinutesAndSecondsToSeconds } from './date';

defineRule('required', required);
defineRule('email', email);
defineRule('numeric', numeric);
defineRule('min_value', (value, args) => {
    if (value && String(value).trim() != '') {
        return min_value(parseFloat(value), args);
    }

    return true;
});
defineRule('max_value', (value, args) => {
    if (value && String(value).trim() != '') {
        return max_value(parseFloat(value), args);
    }

    return true;
});
defineRule('regex', regex);
defineRule('confirmed', confirmed);
defineRule('one_of', one_of);

defineRule('min_duration_seconds', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToSeconds(value);
        if (seconds < args[0]) {
            return false;
        }
    }

    return true;
});
defineRule('max_duration_seconds', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToSeconds(value);
        if (seconds > args[0]) {
            return false;
        }
    }

    return true;
});
defineRule('min_value_seconds', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToSeconds(value);
        if (seconds < args[0]) {
            return false;
        }
    }

    return true;
});
defineRule('max_value_seconds', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToSeconds(value);
        if (seconds > args[0]) {
            return false;
        }
    }

    return true;
});

defineRule('min_duration_minutes', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToMinutes(value);
        if (seconds < args[0]) {
            return false;
        }
    }

    return true;
});
defineRule('max_duration_minutes', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToMinutes(value);
        if (seconds > args[0]) {
            return false;
        }
    }

    return true;
});
defineRule('min_value_minutes', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToMinutes(value);
        if (seconds < args[0]) {
            return false;
        }
    }

    return true;
});
defineRule('max_value_minutes', (value, args) => {
    if (value && String(value).trim() != '') {
        const seconds = convertDurationWithMinutesAndSecondsToMinutes(value);
        if (seconds > args[0]) {
            return false;
        }
    }

    return true;
});

defineRule('training_volume_capability_min_long_session', (value, args) => {
    if (value && String(value).trim() != '') {
        const minLongSession = args[0];

        if (value < minLongSession && typeof minLongSession != 'undefined') {
            return false;
        }
    }

    return true;
});

defineRule('long_session_capability_min_training_volume', (value, args) => {
    if (value && String(value).trim() != '') {
        const minVolume = args[0];

        if (value > minVolume && typeof minVolume != 'undefined') {
            return false;
        }
    }

    return true;
});

defineRule('long_session_capability_min_training_volume_onboarding', (value, args) => {
    if (value && String(value).trim() != '') {
        const minVolume = args[0];

        if (value > minVolume && typeof minVolume != 'undefined') {
            return false;
        }
    }

    return true;
});

configure({
    generateMessage: localize({
        en: i18nEN.validation
    })
});