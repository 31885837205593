import { move } from 'ionicons/icons';
import { TRIQ_API_URL } from '../config';
import axios from 'axios';

const API_ENDPOINT_TRAININGPLAN = '/api/v1/app/training-plan';
const API_ENDPOINT_TRAININGPLAN_VALIDATE = '/api/v1/app/validate-training-plan';
const API_ENDPOINT_TRAININGPLAN_UPDATE = '/api/v1/app/save-edited-training-plan';
const API_ENDPOINT_TRAININGPLAN_RECALCULATE = '/api/v1/app/recalculate-plan';

const API_ENDPOINT_PLANNED_SESSION_SKIP_SUGGESTIONS = '/api/v1/app/planned-sessions/:id/skip-suggestions';
const API_ENDPOINT_PLANNED_SESSION_MOVE = '/api/v1/app/planned-sessions/:id/move';
const API_ENDPOINT_PLANNED_SESSION_UNSKIP = '/api/v1/app/planned-sessions/:id/unskip';
const API_ENDPOINT_PLANNED_SESSION_SKIP = '/api/v1/app/planned-sessions/:id/skip';

export default {
    namespaced: true,

    state: {

    },

    actions: {
        getTrainingPlan({ dispatch }, params) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_TRAININGPLAN, { params: params, headers: config.headers });
            }, { root: true });
        },

        validate({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_TRAININGPLAN_VALIDATE, payload, { headers: config.headers });
            }, { root: true });
        },

        update({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_TRAININGPLAN_UPDATE, payload, { headers: config.headers });
            }, { root: true });
        },

        recalculate({ dispatch }, payload) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_TRAININGPLAN_RECALCULATE, payload, { headers: config.headers });
            }, { root: true });
        },

        getSkipSuggestions({ dispatch }, { plannedSessionId }) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_PLANNED_SESSION_SKIP_SUGGESTIONS.replace(':id', plannedSessionId), { headers: config.headers });
            }, { root: true });
        },

        movePlannedSession({ dispatch }, { plannedSessionId, date }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_PLANNED_SESSION_MOVE.replace(':id', plannedSessionId), { newDate: date }, { headers: config.headers });
            }, { root: true });
        },

        unskipPlannedSession({ dispatch }, { plannedSessionId }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_PLANNED_SESSION_UNSKIP.replace(':id', plannedSessionId), {}, { headers: config.headers });
            }, { root: true });
        },

        skipPlannedSession({ dispatch }, { plannedSessionId }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_PLANNED_SESSION_SKIP.replace(':id', plannedSessionId), {}, { headers: config.headers });
            }, { root: true });
        }
    }
};