import { o } from 'odata';
import { TRIQ_API_URL } from '../config';
import axios from 'axios';

const API_ENDPOINT_GOALS = '/api/v1/odata/athletegoal';
const API_ENDPOINT_GOALS_V2 = '/api/v1/app/goals';
const API_ENDPOINT_GOAL_CREATE = '/api/v1/odata/athletegoal';
const API_ENDPOINT_GOAL = '/api/v1/odata/athletegoal/:raceGoalId';
const API_ENDPOINT_GOAL_UPDATE = '/api/v1/odata/athletegoal/:raceGoalId';
const API_ENDPOINT_GOAL_DELETE = '/api/v1/app/athlete-goals/:raceGoalId';
const API_ENDPOINT_GOAL_TARGET_TIME_OPTIONS = '/api/v1/app/onboarding/targettimeoptions/:athleteId';
const API_ENDPOINT_GOAL_SHOULD_TRIGGER_GENERATION = '/api/v1/app/goals/should-trigger-generation/:raceGoalId';


const API_ENDPOINT_GOAL_VALIDATE_ALL = '/api/v1/app/goals/validate-all';
const API_ENDPOINT_GOAL_VALIDATE_SINGLE = '/api/v1/app/goals/validate-single';

const API_ENDPOINT_GOAL_FEASIBILITY = '/api/v1/app/onboarding/goalfeasibility/:athleteId'

export default {
    namespaced: true,

    state: {
        raceTypeOptions: ['Sprint', 'Olympic', 'HalfIronman', 'Ironman'],
        topographyOptions: [null, 'Hilly', 'Flat'],

        racePriorities: ['A', 'B', 'C'],
        raceTypeOptionsAndPriorities: [
            { value: 'Sprint', priorities: ['A', 'B', 'C'] },
            { value: 'Olympic', priorities: ['A', 'B', 'C'] },
            { value: 'HalfIronman', priorities: ['A', 'B'] },
            { value: 'Ironman', priorities: ['A'] },

            { value: 'Marathon', priorities: ['B'], singleSport: true },
            { value: 'HalfMarathon', priorities: ['B'], singleSport: true },
            { value: 'TenK', priorities: ['C'], singleSport: true },
            { value: 'FiveK', priorities: ['C'], singleSport: true },

            { value: 'ManualRun', priorities: ['B', 'C'], singleSport: true },
            { value: 'ManualBike', priorities: ['B', 'C'], singleSport: true },
            { value: 'ManualSwim', priorities: ['C'], singleSport: true },
        ],

        raceDistanceOptions: {
            ManualSwim: { min: 100, max: 4000, step: 100 }, // Swim
            ManualBike: { min: 1, max: 200, step: 1 },      // Bike
            ManualRun: { min: 1, max: 100, step: 1 },    // Run
        }
    },

    actions: {
        getTargetTimeOptions({ dispatch }, { athlete_id }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOAL_TARGET_TIME_OPTIONS.replace(':athleteId', athlete_id))
                    .query();
            }, { root: true });
        },

        createGoal({ dispatch }, { data, action = null }) {
            return dispatch('auth/request', {action: action, callback: config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .post(API_ENDPOINT_GOAL_CREATE, data)
                    .query();
            }}, { root: true });
        },

        updateGoal({ dispatch }, { id, data, action }) {
            return dispatch('auth/request', {action: action, callback: config => {
                config.headers['Content-Type'] = "application/json";

                return o(TRIQ_API_URL, config)
                    .patch(API_ENDPOINT_GOAL_UPDATE.replace(':raceGoalId', id), data)
                    .query();
            }}, { root: true });
        },

        getGoals({ dispatch }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOALS)
                    .query();
                // .query({
                //     $filter: `AthleteId eq ${athlete_id}`
                // });
            }, { root: true });
        },

        getGoalsV2({ dispatch }, params = {}) {
            return dispatch('auth/request', config => {
                if (params) {
                    config.params = params;
                }
                return axios.get(TRIQ_API_URL + API_ENDPOINT_GOALS_V2, config);
            }, { root: true });
        },

        getGoalFeasibility({ dispatch }, { athlete_id }) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOAL_FEASIBILITY.replace(':athleteId', athlete_id))
                    .query();
            }, { root: true });
        },

        getGoal({ dispatch }, id) {
            return dispatch('auth/request', config => {
                return o(TRIQ_API_URL, config)
                    .get(API_ENDPOINT_GOAL.replace(':raceGoalId', id))
                    .query();
            }, { root: true });
        },

        getGoalPlanUpdateNeeded({ dispatch }, id) {
            return dispatch('auth/request', config => {
                return axios.get(TRIQ_API_URL + API_ENDPOINT_GOAL_SHOULD_TRIGGER_GENERATION.replace(':raceGoalId', id), config);
            }, { root: true });
        },

        validateAll({ dispatch }) {
            return dispatch('auth/request', config => {
                return axios.post(TRIQ_API_URL + API_ENDPOINT_GOAL_VALIDATE_ALL, {}, config);
            }, { root: true });
        },

        validateSingle({ dispatch }, { params }) {
            return dispatch('auth/request', config => {
                if (params) {
                    config.params = params;
                }
                return axios.get(TRIQ_API_URL + API_ENDPOINT_GOAL_VALIDATE_SINGLE, config);
            }, { root: true });          
        },

        deleteGoal({ dispatch }, id) {
            return dispatch('auth/request', config => {
                // return o(TRIQ_API_URL, config)
                //     .delete(API_ENDPOINT_GOAL_DELETE.replace(':raceGoalId', id))
                //     .query();
                return axios.delete(TRIQ_API_URL + API_ENDPOINT_GOAL_DELETE.replace(':raceGoalId', id), config);
            }, { root: true });
        }
    }
};