import axios from 'axios';
import { TRIQ_API_URL } from '../config';

const API_ENDPOINT_ROADMAP_TO_RACE = '/api/v1/app/race-roadmap';
const API_ENDPOINT_ROADMAP_FOR_GOAL = '/api/v1/app/race-roadmap-by-goal/{goalId}';

export default {
    namespaced: true,

    actions: {
        getRoadmapToRace({ dispatch }, params) {
            return dispatch('auth/request', config => {
                config.params = Object.assign(config.params || {}, params);
                // return axios.get(TRIQ_API_URL + API_ENDPOINT_ROADMAP_TO_RACE, config);
                return axios.post(TRIQ_API_URL + API_ENDPOINT_ROADMAP_TO_RACE, null, config);
            }, { root: true });
        },

        getRoadmapForGoal({ dispatch }, { goalId, params }) {
            return dispatch('auth/request', config => {
                config.params = Object.assign(config.params || {}, params);

                return axios.post(TRIQ_API_URL + API_ENDPOINT_ROADMAP_FOR_GOAL.replace('{goalId}', goalId), null, config);
            }, { root: true });
        }
    }
};