// import * as Sentry from "@sentry/capacitor";
import * as Sentry from "@sentry/vue";
// import * as SentryBrowser from "@sentry/browser";
// import { Replay } from "@sentry/replay";

import { ref } from 'vue';
import { config } from './config'

const sentryIsRunning = ref(false);
let appRef = null;

function startSentry(app = null) {
    if (!appRef) {
        appRef = app;
    }

    if (!app) {
        app = appRef;
    }

    Sentry.init(
        {
            dsn: config.SENTRY_DSN,
            // To set your release and dist versions
            release: "triq-app@" + config.SENTRY_VERSION,
            environment: config.ENVIRONMENT,
            dist: "1",
            tracesSampleRate: config.SENTRY_TRACE_SAMPLE_RATE,
            // replaysSessionSampleRate: config.SENTRY_TRACE_SAMPLE_RATE,
            // replaysOnErrorSampleRate: 1.0,
            integrations: [
                // new BrowserTracing({
                //     tracingOrigins: ["localhost", config.FRONTEND_BASE_URL, config.TRIQ_IDENTITY_PROVIDER_URL, config.TRIQ_API_URL],
                // }),
                Sentry.browserTracingIntegration(),
                // SentrySibling.replayIntegration({
                //     maskAllText: true,
                //     blockAllMedia: true
                // })
                // new Replay({
                //     maskAllText: false,
                //     blockAllMedia: false,
                // }),
            ],
    
            tracePropagationTargets: ["localhost", config.FRONTEND_BASE_URL, config.TRIQ_IDENTITY_PROVIDER_URL, config.TRIQ_API_URL],

            app: app
        },
        // SentrySibling.init
    );

    sentryIsRunning.value = true;
}

function stopSentry() {
    Sentry.close();
    sentryIsRunning.value = false;
}

function isSentryRunning() {
    return sentryIsRunning;
}

export { startSentry, stopSentry, isSentryRunning };